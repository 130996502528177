<template>
  <div class="center">
    <vs-dialog width="550px" prevent-close not-close not-center v-model="showDialog">
      <template #header>
        <h4 class="not-margin">
          <b>温馨提示: 请注意您的账号和财产安全</b>
        </h4>
      </template>

      <div class="con-content">
        <p>
          您即将离开本站，去往：<i><b>{{ targetUrlDescription }}</b></i>
          <br>
          <b style="font-size: larger;">{{ targetUrl }}</b>
        </p>
      </div>

      <template #footer>
        <div class="con-footer">
          <vs-button @click="cancel" danger>
            取消
          </vs-button>
          <vs-button @click="confirm" success>
            确认
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'JumpConfirm',
  data () {
    return {
      showDialog: true,

      targetUrl: '',
      targetUrlDescription: ''
    }
  },
  watch: {
  },
  methods: {
    confirm () {
      window.open(this.targetUrl, '_blank')
    },
    cancel () {
      this.$router.go(-1)
    }
  },
  mounted () {
  },
  created () {
    if (this.$store.getters.targetUrl) {
      this.targetUrl = this.$store.getters.targetUrl
      this.targetUrlDescription = this.$store.getters.targetUrlDescription
    } else {
      this.$router.push('/')
    }
  }
}
</script>

<style>
.con-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.con-footer .vs-button {
  margin: 0px;
}

.con-footer .vs-button__content {
  padding: 10px 30px;
}

.con-footer .vs-button ~ .vs-button {
  margin-left: 10px;
}

.con-footer .not-margin {
  margin: 0px;
  font-weight: normal;
  padding: 10px;
  padding-bottom: 0px;
}

.con-content {
  width: 100%;

  /* 自动换行 */
  word-wrap:break-word;
  word-break:normal;
}

.con-content p {
  font-size: 0.8rem;
  padding: 0px 10px;
}

.con-content .vs-checkbox-label {
  font-size: 0.8rem;
}

.con-content .vs-input-parent {
  width: 100%;
}

.con-content .vs-input-content {
  margin: 10px 0px;
  width: calc(100%);
}

.con-content .vs-input {
  width: 100%;
}

.footer-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100%);
}

.footer-dialog .new {
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  font-size: 0.7rem;
}

.footer-dialog .new a {
  color: #1976d2 !important;
  margin-left: 6px;
}

.footer-dialog .new a:hover {
  text-decoration: underline;
}

.footer-dialog .vs-button {
  margin: 0px;
}
</style>
